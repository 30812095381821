import React, { useState }  from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../Button';
import {Link , navigate} from 'gatsby'
import HeaderLink from '../headerLink'
import { Helmet } from "react-helmet"

function buttonClick(e){
  e.preventDefault();
  setHidden(!hidden)
  console.log(hidden)
}


function Header() { 

  const [hidden, setHidden] = useState(true);

  return(
  <header className="sticky z-30 top-0 bg-white shadow " id="header">
    <div className={`container flex ${hidden ? "flex-row" : "flex-col"} justify-between items-center mx-auto py-3 px-8`}>
      <div className="cursor-pointer flex font-bold items-center text-2xl" onClick={() => navigate("/")}>
        <div className="text-green-500">Cantabria</div>
        <div>
          <p className="flex flex-row items-center">
            Digital
            <svg className="text-green-400" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 16.5V18.5H2.80516L6.13849 16.5H19Z" fill="currentColor" stroke="currentColor"/>
            </svg>
          </p>
        </div>
      </div>


      <div className={hidden ? "hidden md:flex md:items-center" : "md:flex md:items-center"}>
        <div className={`flex ${hidden ? "flex-row" : "flex-col mb-4 justify-left"} mt-4 sm:mt-0`}>
          <HeaderLink className="px-4 headerLink text-lg hover:text-green-500" id="char_link" href="#char">
            Características
          </HeaderLink>
          <HeaderLink className="px-4 headerLink text-lg hover:text-green-500" id="serv_link" href="#serv">
            Servicios
          </HeaderLink>
          <HeaderLink className="px-4 headerLink text-lg hover:text-green-500" id="tec_link" href="#tec">
            Tecnología
          </HeaderLink>
          <HeaderLink className="px-4 headerLink text-lg hover:text-green-500" id="blog_link" href="#blog">
            Blog
          </HeaderLink>
        </div>
      </div>  
      
      <div className=" hidden lg:block">
          <Link className="group py-3 px-8 bg-green-500 hover:bg-black rounded-lg text-white font-black transition duration-500 flex items-center" type="button" to="/contact/">Contacta con nosotros
              <svg className="h-6 ml-3 ease-in-out transition duration-500 transform group-hover:translate-x-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg>
          </Link>
      </div>

      <div className="block md:hidden"> 
          <button  onClick={() => setHidden(!hidden)} className="flex items-center px-3 py-2 border rounded text-green-400 border-green-400">
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
      </div>

    </div>
  </header>
);
}

export default Header;
