import React, {useEffect} from 'react';
import Footer from './Footer';
import Header from './Header';
import firebase from "gatsby-plugin-firebase"

const Layout = ({ children }) => {
  
  React.useEffect(() => {
    firebase.analytics()}, [])

  return (
    <>
      <Header />
        <main >{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
