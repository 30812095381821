import React, {Component} from 'react'
import { navigate } from "gatsby"

class AnchorLink extends Component {
  constructor(props) {
    super(props)
    this.smoothScroll = this.smoothScroll.bind(this)
    this.scrolldown = this.scrolldown.bind(this)

  }
  componentDidMount() {

  }

  componentWillUnmount(){

  }

  isTop(el) {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById(this.props.href.slice(1));
    if (this.isTop(wrappedElement)) {
      document.getElementById(this.props.id).classList.add("active");
      console.log('reached '+this.props.id);
    }
    else if(this.isBottom(wrappedElement)){
      document.getElementById(this.props.id).classList.remove("active");
    }
  };

  scrolldown(e){
    let offset = () => 0
      if (typeof this.props.offset !== 'undefined') {
        if (!!(this.props.offset && this.props.offset.constructor && this.props.offset.apply)) {
          offset = this.props.offset
        } else {
          offset = () => parseInt(this.props.offset)
        }
      }
      const id = e.currentTarget.getAttribute('href').slice(1)
      const $anchor = document.getElementById(id);
      const header = document.getElementById("header").getBoundingClientRect().top;
      const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset -header;
      window.scroll({
        top: offsetTop - offset(),
        behavior: 'smooth'
      })
  }

  smoothScroll(e) {
    e.preventDefault()
    if(window.location.pathname != "/"){
      navigate("/"+this.props.href)
    }
    else{
      this.scrolldown(e)
    } 
    if (this.props.onClick) {this.props.onClick(e)}
  }

  render() {
    const { offset, ...rest } = this.props;
    return (
      <a {...rest} onClick={this.smoothScroll} />
    )
  }
}

export default AnchorLink